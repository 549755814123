/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'

import { Box, Count, LoadingSimple } from 'components/elements'
import { Button } from 'components/form'
import { Content, Scroll, Text, View } from 'components/helpers'
import { Footer, Header } from 'container'
import { useProduct } from 'hooks/useProduct'
import { getToken } from 'services/AuthService'
import { getOfferBySlug } from 'services/stores'
import { RootState } from 'store/reducers'
import { setScreenAction } from 'store/reducers/appReducer'
import { numberFormat } from 'utils/helper/currency'
import { getUserLocalLocation } from 'utils/helper/location'
import { truncate } from 'utils/helper/string'

const StorePage = (props: any) => {
  const { addressTemporary, user, cart } = useSelector(
    (state: RootState) => state
  )
  const dispatch = useDispatch()
  const isLogged = getToken()
  const [loading, setLoading] = useState(true)
  const [isNear, setIsNear] = useState(false)
  const [data, setData] = useState([])
  const router = useRouter()

  const { handleAddOfferCart, handleIncrement, handleDecrement } = useProduct()

  const handleData = async () => {
    if (props?.slug) {
      try {
        const location = getUserLocalLocation(user, addressTemporary)
        const { data } = await getOfferBySlug(
          props.slug,
          location?.lat,
          location?.lng
        )
        setData(data.Items)
        setIsNear(data.nearby_store)
        setLoading(false)
      } catch (error: any) {
        /* empty */
      }
    }
  }

  useEffect(() => {
    if (!isLogged && !addressTemporary.hasTemporaryAddress) {
      dispatch(setScreenAction('Start'))
    } else {
      handleData()
    }
  }, [addressTemporary])

  const inProductCart = useCallback(
    (idProduct: string) => {
      return cart.items.find((item) => item.pub_id === idProduct)
    },
    [cart]
  )

  const productQuantity = useCallback(
    (idProduct: string) => {
      return cart.items.find((item) => item.pub_id === idProduct)?.quantity ?? 0
    },
    [cart]
  )

  const handleAddOffer = (offerId: string, product: any) => () =>
    dispatch(handleAddOfferCart(offerId, product))

  const handleRedirectHome = () => {
    router.push('/')
  }

  return (
    <>
      <Header
        logo={props.store.logo_url}
        background={props.store.color}
        isNear={isNear}
      />
      <Content paddingTop='0rem'>
        {loading && (
          <View
            style={{ height: '400px', width: '100%' }}
            justify='center'
            align='center'
          >
            <LoadingSimple />
          </View>
        )}

        {!loading &&
          (data?.length > 0 ? (
            <>
              <View justify='center' mt={1}>
                <Text size={22} color='#EA2A2F' fontWeight={700}>
                  Ofertas exclusivas
                </Text>
              </View>
              <View pt={2} pb={1}>
                <Scroll className='showcase'>
                  {data.map((item: any, index: number) => (
                    <Box key={index} className='showcase__item'>
                      <Link href={`/oferta/${item.slug}`} passHref>
                        <a className='showcase__link'>
                          <img
                            src={item.product.img}
                            height={150}
                            alt={item.product.name}
                            className='showcase__image'
                          />
                          <View mt={1.2} height='2rem'>
                            <Text
                              textAlign='left'
                              fontWeight={500}
                              color='#474747'
                            >
                              {truncate(item.product.name, 60)}
                            </Text>
                          </View>
                        </a>
                      </Link>

                      <View style={{ marginTop: 'auto', width: '100%' }}>
                        <View
                          direction='row'
                          align='center'
                          justify='space-between'
                          mb={1}
                          height='1rem'
                        >
                          <Text
                            textAlign='center'
                            size={16}
                            fontWeight={300}
                            color='#474747'
                            style={{ textDecoration: 'line-through' }}
                          >
                            {numberFormat(item.product.normal_price)}
                          </Text>
                          <Text
                            size={22}
                            textAlign='center'
                            fontWeight={700}
                            color='#00997F'
                          >
                            {numberFormat(item.product.price)}
                          </Text>
                        </View>
                        {inProductCart(item.pub_id) ? (
                          <View height='2.7rem' align='center'>
                            <Count
                              quantity={productQuantity(item.pub_id)}
                              onIncrement={handleIncrement(item.pub_id)}
                              onDecrement={handleDecrement(item.pub_id)}
                            />
                          </View>
                        ) : (
                          isNear && (
                            <Button
                              label='Adicionar'
                              background='#3097E2'
                              onClick={handleAddOffer(
                                item.pub_id,
                                item.product
                              )}
                            />
                          )
                        )}
                      </View>
                    </Box>
                  ))}
                </Scroll>
              </View>
            </>
          ) : (
            <View mt={2} justify='center' align='center'>
              <Image
                src='/icon/empty-store.svg'
                width='100'
                height='100'
                alt='Loja vazia'
                priority
              />
              <View mt={2}>
                <Text fontWeight={400}>Nenhuma oferta cadastrada</Text>
              </View>
              <View width='300px' mt={1}>
                <Button
                  id='add_mais_produtos'
                  label='Adicionar mais produtos'
                  background='#fff'
                  color='#3097E2'
                  borderColor='#3097E2'
                  onClick={handleRedirectHome}
                />
              </View>
            </View>
          ))}
      </Content>
      <Footer />
    </>
  )
}

export default StorePage
